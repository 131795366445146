export class PersistentIntervalManager {
    private readonly callbacks: Map<string, () => Promise<void>>;
    private readonly alarms: Map<string, { alarm: number, interval: number }>;

    constructor() {
        this.callbacks = new Map();
        this.alarms = this.getExecutionAlarms();
        this.startHousekeeping();
    }

    async continue(id: string, interval: number, callback: () => Promise<void>) {
        if (!this.alarms.has(id)) {
            this.setNextExecutionAlarm(id, Date.now(), interval * 1000);
        }
        this.callbacks.set(id, callback);
    }

    private getExecutionAlarms = () => {
        const stored = localStorage.getItem(`persistent_intervals_alarms`);
        if (!stored) {
            return new Map();
        }
        return new Map(JSON.parse(stored));
    };

    private setNextExecutionAlarm(id: string, alarmTime: number, interval: number) {
        this.alarms.set(id, {'alarm': alarmTime, interval: interval});
        localStorage.setItem(`persistent_intervals_alarms`, JSON.stringify(Array.from(this.alarms.entries())));
    }

    startHousekeeping = () => {
        setInterval(async () => {
            const now = Date.now();
            for (let [id, alarm] of this.alarms) {
                if (alarm.alarm <= now) {
                    const nextExecutionTime = Date.now() + alarm.interval;
                    const callback = this.callbacks.get(id);
                    if (callback) {
                        await callback()
                        this.setNextExecutionAlarm(id, nextExecutionTime, alarm.interval);
                    } else {
                        console.log('Callback execution not successful');
                    }
                }
            }
        }, 1000);
    };

    stop(id: string) {
        this.alarms.delete(id);
        this.callbacks.delete(id);
        localStorage.setItem(`persistent_intervals_alarms`, JSON.stringify(Array.from(this.alarms.entries())));
    }
}

export const intervalManager = new PersistentIntervalManager();