import {useContext, useEffect, useState} from "react";
import {BackendTokenContext} from "./BackendTokenContext";
import {useIsConnectionRestored, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {backendAuth} from "./backend-auth";
import {intervalManager} from "./Common/IntervalManager";
import {ConnectAdditionalRequest} from "@tonconnect/ui";

const payloadTTLSeconds = 24 * 60 * 60;

export function useBackendAuth() {
    const {setToken, setWalletAddress} = useContext(BackendTokenContext);
    const isConnectionRestored = useIsConnectionRestored();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    const payload = useBackendPayloadForTonConnect();

    useEffect(() => {
        if (!setToken || !setWalletAddress) {
            return;
        }

        if (localStorage.getItem('backend_auth_token')) {
            setToken(localStorage.getItem('backend_auth_token'));
            setWalletAddress(localStorage.getItem('backend_auth_address'));
            return;
        }
        if (wallet && wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
            backendAuth.checkProof(wallet.connectItems.tonProof.proof, wallet.account).then(result => {
                setToken(result!);
                setWalletAddress(localStorage.getItem('backend_auth_address'));
                localStorage.setItem('backend_auth_token', result!);
            });
        }
    }, [wallet, isConnectionRestored, tonConnectUI, setToken, payload, setWalletAddress])
}

export function useBackendPayloadForTonConnect() {
    const [payload, setPayload] = useState<ConnectAdditionalRequest | null>(null);
    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonWallet();

    useEffect(() => {
        const refreshPayload = async () => {
            tonConnectUI.setConnectRequestParameters({state: 'loading'});
            const value = await backendAuth.generatePayload();
            tonConnectUI.setConnectRequestParameters({state: 'ready', value});
            localStorage.removeItem('backend_auth_token');
            localStorage.setItem('backend_auth_payload', JSON.stringify(value));
            if (tonConnectUI.connected) {
                await tonConnectUI.disconnect();
            }
            setPayload(value);
        }

        tonConnectUI.setConnectRequestParameters({
            state: 'ready',
            value: JSON.parse(localStorage.getItem('backend_auth_payload') ?? '{}')
        });

        if (wallet?.account.address && wallet?.account.address !== localStorage.getItem('backend_auth_address')) {
            intervalManager.stop('backend_auth_refresh_payload');
            intervalManager.continue('backend_auth_refresh_payload', payloadTTLSeconds, refreshPayload);
            localStorage.setItem('backend_auth_address', wallet!.account.address);
        } else {
            intervalManager.continue('backend_auth_refresh_payload', payloadTTLSeconds, refreshPayload);
        }

    }, [wallet, tonConnectUI, payload]);
    return payload;
}