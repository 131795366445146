import React from "react";
import './ProfileExplanation.scss';
import {GalleryTonConnectButton} from "../GalleryTonConnectButton";
import {useBackendAuth} from "../useBackendAuth";

export function ProfileExplanation() {
    useBackendAuth();
    return <div className={'profile-explanation'}>
        <div className={'profile-explanation-frame'}>
            <div className="profile-explanation-frame-header"><h3>So you are an artist?</h3></div>
            <div className="profile-explanation-frame-body inverted">
                You can add your art either to our <span
                className="inverted">COLLECTIONs</span> or <span
                className="inverted">YOUR PERSONAL GALLERY</span>.
                <br/>
                But at first please connect your wallet.
            </div>
            <div className="profile-explanation-frame-call-to-action">
                <GalleryTonConnectButton/>
            </div>
        </div>
    </div>;
}