import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {TonConnectUIProvider} from '@tonconnect/ui-react';
import {MainScene} from "./MainScene";
import './global.d.ts';
import {Provider} from "react-redux";
import {store} from './store';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const App = () => {
    return (
        <div>
            <TonConnectUIProvider manifestUrl={"https://not-nft-gallery.pages.dev/tonconnect-manifest.json"}
                                  walletsListConfiguration={{includeWallets: []}}
                                  uiPreferences={{borderRadius: 'none'}}
                                  actionsConfiguration={{
                                      twaReturnUrl: 'https://t.me/easy_mints_bot'
                                  }}>
                <Provider store={store}>
                    <MainScene/>
                </Provider>
            </TonConnectUIProvider>
        </div>
    );
};

root.render(<App/>);
