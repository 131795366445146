// store.js
import {createStore} from 'redux';

// Actions
const START_LOADING = 'START_LOADING';
const STOP_LOADING = 'STOP_LOADING';

export const startLoading = () => ({type: START_LOADING});
export const stopLoading = () => ({type: STOP_LOADING});

// Reducer
const initialState = {loading: false};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return {...state, loading: true};
        case STOP_LOADING:
            return {...state, loading: false};
        default:
            return state;
    }
};
export const store = createStore(loadingReducer);
