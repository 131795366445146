import React from "react";
import './AddNFT.scss';
import {BackendTokenContext} from "../BackendTokenContext";
import {FileInfo} from "@uploadcare/react-widget";

import {UploadCare} from "../Common/UploadCare";
import {Loader} from "../Loader";
import ImageHelper from "../Common/ImageHelper";
import {profileBackend} from "./ProfileBackend";
import {NFTCardInfo} from "../NFTCard";
import {EventEmitter} from "../Common/EventEmitter";


type Props = { onNFTAdded?: (any: NFTCardInfo) => void };

export class AddNFT extends React.Component<Props, {
    img?: string,
    loading: boolean
}> {
    static contextType = BackendTokenContext;
    private uploadcarePilot: EventEmitter;

    constructor(props: Props) {
        super(props);
        this.uploadcarePilot = new EventEmitter();
        this.state = {loading: false};
    }

    private onUpload = async (info: FileInfo): Promise<void> => {
        const img = new Image();
        const imgLoaded = new Promise((resolve, reject) => {
            img.onload = () => {
                resolve(img);
            }
        });

        const blured = new Image();
        const bluredLoaded = new Promise((resolve, reject) => {
            blured.onload = () => {
                resolve(img);
            }
        });
        blured.src = ImageHelper.blured(info.originalUrl!);
        img.src = info.originalUrl!;
        // @ts-ignore
        await Promise.all([bluredLoaded, imgLoaded]);
        this.setState({img: info.originalUrl!});
    }


    private onUploadStart = () => {
        this.setState({loading: true});
    };


    render() {
        return <div className={`add-nft ${this.state.img ? 'add-nft-with-image' : null}`}>
            <div className="add-nft-background">
                {this.getBackground()}
            </div>
            <div className="add-nft-ontop">
                <UploadCare pilot={this.uploadcarePilot} onChange={this.onUpload} onUploadStart={this.onUploadStart}/>
            </div>
        </div>;
    }


    private save = async () => {
        // @ts-ignore
        const img = this.state.img;
        this.setState({img: undefined, loading: true});
        const nft = await profileBackend.addNFT(img!, (this.context! as any).token);
        this.setState({loading: false});
        this.uploadcarePilot.emit('reload');
        if (this.props.onNFTAdded) {
            this.props.onNFTAdded(nft);
        }
    };

    private getBackground() {
        if (this.state.img) {
            return <>
                <div className={'add-nft-background-image-background-blur'}>
                    <div>
                        <div className="add-nft-background-image-background-blur-white-shadow"></div>
                        <img className={''}
                             src={ImageHelper.blured(this.state.img)} alt=""/>
                    </div>
                </div>
                <img className={'add-nft-background-image'} src={this.state.img} alt=""/>
                <div className="add-nft-background-save">
                    <button className="add-nft-background-save-button inverted" onClick={this.save}>
                        SAVE
                    </button>
                </div>
            </>
        }
        if (this.state.loading) {
            return <Loader/>
        }
        return <>
            <h3 className="inverted">CLICK TO UPLOAD YOU NFT</h3>
            <div className="add-nft-sign">
                +
            </div>
            <h3 className="inverted">OR SCROLL TO SEE UPLOADED {'>>>'} </h3>
        </>;
    }
}