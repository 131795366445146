import {NFTCardInfo} from "../NFTCard";

export class ProfileBackend {

    constructor(private baseUrl: string) {
    }

    async findNFTs() {
        const res = await fetch(this.baseUrl + `/nft`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return (await res.json()) as NFTCardInfo[];
    }
}

export const nftBackend = new ProfileBackend(process.env.REACT_APP_BACKEND!);