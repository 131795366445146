type Callback = (data: any) => void;

export class EventEmitter {
    private callbacks: { [event: string]: Callback[] };

    constructor() {
        this.callbacks = {};
    }

    on(event: string, cb: Callback): void {
        if (!this.callbacks[event]) {
            this.callbacks[event] = [];
        }
        this.callbacks[event].push(cb);
    }

    emit(event: string, data?: any): void {
        const cbs = this.callbacks[event];
        if (cbs) {
            cbs.forEach(cb => cb(data));
        }
    }
}