import 'swiper/css';
import 'swiper/css/bundle';
import './NFTSwiper.scss';
import React, {createRef} from "react";
import {Swiper, SwiperRef} from "swiper/react";
import {EffectCards} from "swiper/modules";
import {EventEmitter} from "./Common/EventEmitter";


export default class NFTSwiper extends React.Component<{
    onScroll?: any,
    pilot?: EventEmitter
    children: React.ReactNode
}, any> {
    ref: React.RefObject<SwiperRef>;

    constructor(props: { onScroll: any, children: React.ReactNode }, context: any) {
        super(props, context);
        this.ref = createRef();
    }

    componentDidMount() {
        if (this.props.pilot) {
            this.props.pilot.on('scrollNext', () => {
                this.ref.current?.swiper.slideNext();
            })
        }
    }

    onScroll = (el: any) => {
        if (this.props.onScroll) {
            this.props.onScroll(el);
        }
    }

    render() {
        return <>
            <div className="nft-swiper-container">
                <Swiper
                    ref={this.ref}
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChangeTransitionStart={this.onScroll}
                    className={'nft-swiper'}
                >
                    {this.props.children}
                </Swiper>
            </div>
        </>
    }
}
