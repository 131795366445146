import React from "react";
import './LogoPreview.scss';
import Typed from "./Common/Typed";
export class LogoPreview extends React.Component {
    render() {
        return <div className="logo-preview">
            <Typed elem={'h1'} title={'NOT'}></Typed>
            <Typed elem={'h1'} className={'inverted'} title={'NFT'}></Typed>
            <Typed elem={'h1'} title={'GALLERY'}></Typed>
            <Typed elem={'span'} title={'is the future of artist collaboration on not platform. We will launch not art fairs with different challenges. Claim your not artist identity to participate.'}></Typed>
        </div>;
    }
}