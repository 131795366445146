import React, {createRef} from "react";
import Typedjs from "typed.js";

class Typed extends React.Component {

    constructor(props) {
        super(props);
        this.textRef = createRef();
        this.textSizeMeasureRef = createRef();
        this.state = {
            textMeasure: ''
        };
    }

    text = (el, text) => {
        return new Typedjs(el, {
            strings: [text],
            typeSpeed: this.props.typeSpeed ?? 50,
            backSpeed: this.props.backSpeed ?? 15,
            cursorChar: this.props.cursorChar ?? '█',
        });
    }

    componentDidMount = () => {
        this.typed = this.text(this.textRef.current, this.props.title);
    }

    componentWillUnmount() {
        this.typed.destroy();
    }

    render() {
        if(this.props.elem === 'h1'){
            return <>
                <h1 className={this.props.className + " typed-text"} ref={this.textRef}></h1>
            </>;
        }
        if(this.props.elem === 'h4'){
            return <>
                <h4 className={this.props.className + " typed-text"} ref={this.textRef}></h4>
            </>;
        }
        return <>
            <span className={this.props.className + " typed-text"} ref={this.textRef}></span>
        </>;
    }
}

export default Typed;
