import React, {useState} from "react";
import {ProfileExplanation} from "./ProfileExplanation";
import {BackendTokenContext} from "../BackendTokenContext";
import {useTonWallet} from "@tonconnect/ui-react";
import {ProfileCreateOrGetMiddleware} from "./ProfileCreateOrGetMiddleware";

export function ProfileConnectAuthMiddleware() {
    const [token, setToken] = useState<string | null>(null);
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const wallet = useTonWallet();
    return <BackendTokenContext.Provider value={{token, setToken, walletAddress, setWalletAddress}}>
        {wallet && token ? <ProfileCreateOrGetMiddleware/> : <ProfileExplanation/>}
    </BackendTokenContext.Provider>;
}
