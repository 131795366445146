import React from "react";
import {FileInfo, Widget} from "@uploadcare/react-widget";
import './UploadCare.scss';
import {EventEmitter} from "./EventEmitter";

interface Props {
    pilot: EventEmitter;
    onChange: (info: FileInfo) => void;
    onUploadStart: () => void;
}

export class UploadCare extends React.Component<Props, { key: number }> {

    constructor(props: Props) {
        super(props);
        this.state = {key: 1};
    }

    componentDidMount() {
        this.props.pilot.on('reload', () => {
            this.setState({key: this.state.key + 1})
        })
    }

    render() {
        return <Widget
            key={this.state.key}
            publicKey="31b6285f23d864bc7287" multiple={false}
            inputAcceptTypes={'image/png, image/jpeg'}
            onFileSelect={this.props.onUploadStart}
            onChange={this.props.onChange}/>;
    }
}