import {Profile} from "../ProfileContext";
import {NFTCardInfo} from "../NFTCard";

export class ProfileBackend {

    constructor(private baseUrl: string) {
    }

    async createOrUpdateProfile(token: string): Promise<Profile> {
        const res = await fetch(this.baseUrl + '/users', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return res.json();
    }

    async updateName(name: string, token: string): Promise<Profile> {
        const res = await fetch(this.baseUrl + '/users', {
            method: 'PATCH',
            body: JSON.stringify({
                name: name
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return res.json();
    }

    async updateInstagram(instagram: string, token: string): Promise<Profile> {
        const res = await fetch(this.baseUrl + '/users/instagram', {
            method: 'POST',
            body: JSON.stringify({
                instagram: instagram
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return res.json();
    }

    async addNFT(src: string, token: string): Promise<NFTCardInfo> {
        const res = await fetch(this.baseUrl + '/users/nft', {
            method: 'POST',
            body: JSON.stringify({
                src: src,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return res.json();
    }

    async findNFTsForAuthor(address: string) {
        const res = await fetch(this.baseUrl + `/nft?creator=${address}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            throw new Error("Backend api error");
        }
        return (await res.json()) as NFTCardInfo[];
    }
}

export const profileBackend = new ProfileBackend(process.env.REACT_APP_BACKEND!);