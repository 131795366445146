import React, {Component, createRef} from "react";
import io, {Socket} from "socket.io-client";
import './NFTCard.scss';
import Typed from "./Common/Typed";
import BrailleImageConverter from "./Common/BraillieImageConverter";

const socket: Socket = io(process.env.REACT_APP_VIDEO_BACKEND as string);

interface Profile {
    name?: string;
    instagram?: string;
}

export interface NFTCardInfo {
    src: string;
    name?: string;
    creator: Profile;
    spacePrice?: number;
    tonPrice?: number;
}

interface NFTCardProps {
    info: NFTCardInfo;
    active?: boolean;
}

interface NFTCardState {
    imageSrc: string | null;
    waiting: boolean;
    brailleImage: string | null;
}

class NFTCard extends Component<NFTCardProps, NFTCardState> {
    videoRef: React.RefObject<HTMLVideoElement>;
    currentStream: MediaStream | null;
    facingMode: 'user' | 'environment' = 'user';
    interval?: number;
    backwardsAnimation: string[] | null = null;

    constructor(props: NFTCardProps) {
        super(props);
        this.state = {
            imageSrc: null,
            waiting: false,
            brailleImage: '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣟⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣯⣻⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡽⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿ \n' +
                '⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣿'
        };
        this.videoRef = createRef();
        this.currentStream = null;
        this.facingMode = 'user';
    }


    componentDidMount() {
        this.startCamera(this.facingMode);
        socket.on('image', async (data: string) => {
            let converter = new BrailleImageConverter();
            const animateResult = await converter.convertAnimate(`data:image/png;base64,${data}`);
            if (this.backwardsAnimation) {
                await this.transition(this.backwardsAnimation)
            }
            this.backwardsAnimation = [...animateResult].reverse();
            this.setState({imageSrc: `data:image/png;base64,${data}`});
            await this.transition(animateResult);
            this.setState({waiting: false});
        });
        socket.on('error', (error: string) => {
            console.error('Socket error:', error);
        });

        if (!this.interval) {
            // @ts-ignore
            this.interval = setInterval(this.sendImage, 5000);
        }
    }

    private async transition(animateResult: string[]) {
        return new Promise(resolve => {
            let i = 0;
            const interval = setInterval(() => {
                this.setState({brailleImage: animateResult[i]});
                i++;
                if (i === animateResult.length) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 25);
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.currentStream) {
            this.currentStream.getTracks().forEach(track => track.stop());
        }
    }

    startCamera = async (facingMode: 'user' | 'environment') => {
        if (this.currentStream) {
            this.currentStream.getTracks().forEach(track => track.stop());
        }

        try {
            this.currentStream = await navigator.mediaDevices.getUserMedia({
                video: {facingMode: facingMode, width: 256, height: 256}
            });
            this.videoRef.current!.srcObject = this.currentStream;
        } catch (error) {
            console.error('Error accessing the camera', error);
        }
    };

    captureImage = (): string => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context && this.videoRef.current) {
            canvas.width = this.videoRef.current.videoWidth;
            canvas.height = this.videoRef.current.videoHeight;
            context.drawImage(this.videoRef.current, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL('image/png').split(',')[1];
        }
        return '';
    };

    sendImage = () => {
        if (this.state.waiting) {
            return;
        }
        const image = this.captureImage();
        socket.emit('image', image)
        this.setState({waiting: true});
    };

    switchCamera = async () => {
        this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
        await this.startCamera(this.facingMode);
    };

    render() {
        return (
            <div className={'nft-card-frame'}>
                <div className={"nft-card"}>
                    <div className="nft-card-caption">
                        <div className="nft-card-name"></div>
                    </div>
                    <div className={'nft-card-frame-image'}>
                        <div className="nft-card-frame-image-image-background-blur">
                            <div>
                                <div className="nft-card-frame-image-image-background-blur-white-shadow"></div>
                            </div>
                        </div>
                        {this.props.active ? (
                            <Typed elem={'h2'} className={'inverted'} title={'Break Through With NOTCOIN.'}/>
                        ) : null}
                        <video ref={this.videoRef} autoPlay style={{display: 'none'}}/>
                        <div className="nft-card-frame-braillie-image">{this.state.brailleImage}
                            <img
                                className={'nft-card-frame-image-image'}
                                src={this.state.imageSrc || ''}
                                alt="Processed"
                                onClick={this.switchCamera}
                            />
                            <div className="nft-card-frame-braillie-image-text">
                                {this.state.brailleImage}
                            </div>
                        </div>

                    </div>
                    <div className="nft-card-frame-corner">
                        {/*<a*/}
                        {/*    className="nft-card-frame-corner-author"*/}
                        {/*    href={`https://www.instagram.com/${this.props.info.creator.instagram ?? 'noname'}`}*/}
                        {/*></a>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default NFTCard;
