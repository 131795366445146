import React, {useContext, useEffect, useState} from 'react';
import {profileBackend} from "./ProfileBackend";
import {BackendTokenContext} from "../BackendTokenContext";
import {Profile} from "../ProfileContext";
import {ProfileEdit} from "./ProfileEdit"; // Adjust path as needed

export function ProfileCreateOrGetMiddleware() {
    const {token} = useContext(BackendTokenContext);
    const [profile, setProfile] = useState<Profile | null>(null);

    useEffect(() => {
        const fetchOrUpdateProfile = async () => {
            if (token && !profile) {
                const profile = await profileBackend.createOrUpdateProfile(token);
                setProfile!(profile);
            }
        }
        fetchOrUpdateProfile();
    }, [token, profile, setProfile]);

    if (!token) {
        return <div>Loading...</div>;
    }

    if (!profile) {
        return <div>Loading profile...</div>;
    }


    return <ProfileEdit profile={profile}/>;
}