import React from "react";
import NFTSwiper from "./NFTSwiper";
import './Collection.scss';
import NFTCard, {NFTCardInfo} from "./NFTCard";
import {SwiperSlide} from "swiper/react";

export class Collection extends React.Component<{ onScroll: (el: any) => void, collection: NFTCardInfo[] }, {
    active: number
}> {

    state = {
        active: 0,
    };

    onScroll = (el: any) => {
        this.props.onScroll(el);
        this.setState({active: el.activeIndex});
    }

    render() {
        return <div className={'collection'}>
            <div className={'collection-scroller'}>
                <NFTSwiper onScroll={this.onScroll}>
                    {this.props.collection.map((i, index) =>
                        <SwiperSlide className={'nft-swiper-slide'}>
                            <NFTCard info={i} active={index === this.state.active}/>
                        </SwiperSlide>)
                    }
                </NFTSwiper>
            </div>
        </div>;
    }
}