import React from "react";
import './BuyButton.scss';
import {listenToCurrentInfo} from "./CurrentInfo";
import {NFTCardInfo} from "./NFTCard";

export class BuyButton extends React.Component<{}, { info?: NFTCardInfo }> {

    constructor(props: any, context: any) {
        super(props, context);
        // @ts-ignore
        this.state = {info: {}};
    }

    componentDidMount() {
        listenToCurrentInfo((i: NFTCardInfo) => this.setState({info: i}));
    }

    render() {
        return <div className={'buy-buttons'}>
            <button className={`buy-button buy-button-with-space ${this.state?.info?.spacePrice ? '' : 'disable'}`}>
                RESERVE <div className="buy-button-vertical-border"></div> 1000
                <img src="/img/spacecoin.svg" alt="" className=""/>
            </button>
            <button className={`buy-button buy-button-with-ton ${this.state?.info?.tonPrice ? '' : 'disable'}`}>
                BUY <div className="buy-button-vertical-border"></div> 10<img src="/img/toncoin.svg" alt=""
                                                                              className=""/>
            </button>
        </div>;
    }
}