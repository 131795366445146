export default class BrailleImageConverter {
    constructor() {
        this.color_threshold = 150;
        this.fill_transparency = true;
    }

    static invert(input_str, dot_for_blank = false) {
        if (dot_for_blank) {
            braille_dic_invert['⣿'] = '⠄';
            braille_dic_invert['⠄'] = '⣿';
        } else {
            braille_dic_invert['⣿'] = '⠀';
            braille_dic_invert['⠄'] = '⢿';
        }

        let result_str = "";
        for (let i = 0; i < input_str.length; i++) {
            if (typeof braille_dic_invert[input_str[i]] !== 'undefined') {
                result_str += braille_dic_invert[input_str[i]];
            } else {
                result_str += input_str[i];
            }
        }
        return result_str;
    }

    async convertAnimate(base64Image) {
        const res = [];
        for(let i = 255; i > 0; i-=10){
            res.push(await this.convert(base64Image, false, i));
        }
        return res;
    }
    // Converts base64 image to Braille string
    async convert(base64Image, dot_for_blank = false, brightness = 100, fill_tran = true, use_dith = false, dith_select = "od") {
        this.fill_transparency = fill_tran;
        this.color_threshold = 255 - brightness;

        let imageData = await this.getImageDataFromBase64(base64Image);
        return this.iterate_over_pixels(imageData.data, imageData.width, dot_for_blank, brightness, fill_tran, use_dith, dith_select);
    }

    // Gets image data from base64 string
    getImageDataFromBase64(base64) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = 60;
                canvas.height = 60;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                resolve(ctx.getImageData(0, 0, canvas.width, canvas.height));
            };
            img.onerror = reject;
            img.src = base64;
        });
    }

    // Function to iterate over pixels
    iterate_over_pixels(data_array, width, dot_for_blank, brightness, fill_tran, use_dith, dith_select){
        let result_array = [];
        let pixel_array = [];
        for(let i=0; i<data_array.length; i+=4){
            pixel_array.push(new Pixel(data_array[i], data_array[i+1], data_array[i+2], data_array[i+3]));
        }

        for(let i=0; i<pixel_array.length; i+=(width*4)){
            let line = "";
            for(let j=0; j<width; j+=2){
                line += braille_descr_dic[this.get_braille_code(pixel_array, i+j, width)];
            }
            result_array.push(line);
        }

        if (dot_for_blank){
            return result_array.join(' \n').replace(/[⠀]/g, '⠄');
        } else {
            return result_array.join(' \n');
        }
    }


    get_braille_code(pixel_array, pos, width){
        let braille_code = "";
        let pixel_pos_to_braille_pos = {
            '00': '1',
            '01': '2',
            '02': '3',
            '03': '7',
            '10': '4',
            '11': '5',
            '12': '6',
            '13': '8'
        };
        for(let k=0; k<2; k++){
            for(let l=0; l<4; l++){
                if ((pos + k + (width*l)) < pixel_array.length){
                    if (this.evaluate_pixel(pixel_array[(pos + k + (width*l))])){
                        braille_code += pixel_pos_to_braille_pos[(k.toString() + l.toString())];
                    }
                }
            }
        }
        return braille_code.split("").map(Number).sort((a, b) => (a - b)).join('');
    }


    evaluate_pixel(pixel){
        if (this.fill_transparency === true && pixel.alpha === 0){
            return true;
        }
        if (pixel.red > this.color_threshold || pixel.green > this.color_threshold || pixel.blue > this.color_threshold){
            return true;
        } else {
            return false;
        }
    }


    ordered_dithering(pixel_array, width, brightness){
        let change_factor = brightness/128;
        let matrix = [[64*change_factor, 128*change_factor], [192*change_factor, 0]];
        for (let i=0; i<pixel_array.length; i++){
            let x = i % width;
            let y = Math.floor(i/width);
            if (pixel_array[i].get_avg() > matrix[(y % matrix.length)][x % matrix.length]){
                pixel_array[i].set_rgb(255, 255, 255);
            } else {
                pixel_array[i].set_rgb(0, 0, 0);
            }
        }
        return pixel_array;
    }



    clip(color){
        if (color > 255){
            color = 255;
        } else if (color < 0){
            color = 0;
        }
        return color;
    }


    floyd_steinberg(pixel_array, width, brightness){
        for (let i=0; i<pixel_array.length; i++){

            let quant_error = [pixel_array[i].red, pixel_array[i].green, pixel_array[i].blue];
            let slider_perc = brightness/255;
            let red_poly = Math.pow(1-slider_perc, 2);
            let green_poly = 2*(1-slider_perc)*slider_perc;
            let blue_poly = Math.pow(slider_perc, 2);

            if ((red_poly*pixel_array[i].red + green_poly*pixel_array[i].green + blue_poly*pixel_array[i].blue) > 128){
                quant_error.forEach((elem, index, array) => array[index] -= 255);
                pixel_array[i].set_rgb(255, 255, 255);
            } else {
                pixel_array[i].set_rgb(0, 0, 0);
            }

            let neighbours = [[1,0,7], [-1,1,3], [0,1,5], [1,1,1]];
            for (const n of neighbours){
                if ((i+(n[1]*width))+n[0] < pixel_array.length && (i % width)+n[0] >= 0 && (i % width)+n[0] < width){
                    let pixel_index = i+(n[1]*width)+n[0];
                    let new_colors = new Array(3);
                    quant_error.forEach((elem, index) => new_colors[index] = Object.values(pixel_array[pixel_index])[index] + elem * n[2] /16);
                    pixel_array[pixel_index].set_rgb(new_colors[0], new_colors[1], new_colors[2]);
                }
            }
        }
        return pixel_array;
    }
}

// Additional Pixel class required for the conversion
class Pixel {
    constructor(red, green, blue, alpha) {
        this.red = red;
        this.green = green;
        this.blue = blue;
        this.alpha = alpha;
    }

    get_avg() {
        return (this.red + this.green + this.blue) / 3;
    }

    set_rgb(red, green, blue) {
        this.red = this.clip(red);
        this.green = this.clip(green);
        this.blue = this.clip(blue);
    }

    clip(color) {
        if (color > 255) {
            color = 255;
        } else if (color < 0) {
            color = 0;
        }
        return color;
    }
}

// Braille dictionary to be defined
const braille_descr_dic = {
    '': '⠀',
    '1': '⠁',
    '2': '⠂',
    '12': '⠃',
    '3': '⠄',
    '13': '⠅',
    '23': '⠆',
    '123': '⠇',
    '4': '⠈',
    '14': '⠉',
    '24': '⠊',
    '124': '⠋',
    '34': '⠌',
    '134': '⠍',
    '234': '⠎',
    '1234': '⠏',
    '5': '⠐',
    '15': '⠑',
    '25': '⠒',
    '125': '⠓',
    '35': '⠔',
    '135': '⠕',
    '235': '⠖',
    '1235': '⠗',
    '45': '⠘',
    '145': '⠙',
    '245': '⠚',
    '1245': '⠛',
    '345': '⠜',
    '1345': '⠝',
    '2345': '⠞',
    '12345': '⠟',
    '6': '⠠',
    '16': '⠡',
    '26': '⠢',
    '126': '⠣',
    '36': '⠤',
    '136': '⠥',
    '236': '⠦',
    '1236': '⠧',
    '46': '⠨',
    '146': '⠩',
    '246': '⠪',
    '1246': '⠫',
    '346': '⠬',
    '1346': '⠭',
    '2346': '⠮',
    '12346': '⠯',
    '56': '⠰',
    '156': '⠱',
    '256': '⠲',
    '1256': '⠳',
    '356': '⠴',
    '1356': '⠵',
    '2356': '⠶',
    '12356': '⠷',
    '456': '⠸',
    '1456': '⠹',
    '2456': '⠺',
    '12456': '⠻',
    '3456': '⠼',
    '13456': '⠽',
    '23456': '⠾',
    '123456': '⠿',
    '7': '⡀',
    '17': '⡁',
    '27': '⡂',
    '127': '⡃',
    '37': '⡄',
    '137': '⡅',
    '237': '⡆',
    '1237': '⡇',
    '47': '⡈',
    '147': '⡉',
    '247': '⡊',
    '1247': '⡋',
    '347': '⡌',
    '1347': '⡍',
    '2347': '⡎',
    '12347': '⡏',
    '57': '⡐',
    '157': '⡑',
    '257': '⡒',
    '1257': '⡓',
    '357': '⡔',
    '1357': '⡕',
    '2357': '⡖',
    '12357': '⡗',
    '457': '⡘',
    '1457': '⡙',
    '2457': '⡚',
    '12457': '⡛',
    '3457': '⡜',
    '13457': '⡝',
    '23457': '⡞',
    '123457': '⡟',
    '67': '⡠',
    '167': '⡡',
    '267': '⡢',
    '1267': '⡣',
    '367': '⡤',
    '1367': '⡥',
    '2367': '⡦',
    '12367': '⡧',
    '467': '⡨',
    '1467': '⡩',
    '2467': '⡪',
    '12467': '⡫',
    '3467': '⡬',
    '13467': '⡭',
    '23467': '⡮',
    '123467': '⡯',
    '567': '⡰',
    '1567': '⡱',
    '2567': '⡲',
    '12567': '⡳',
    '3567': '⡴',
    '13567': '⡵',
    '23567': '⡶',
    '123567': '⡷',
    '4567': '⡸',
    '14567': '⡹',
    '24567': '⡺',
    '124567': '⡻',
    '34567': '⡼',
    '134567': '⡽',
    '234567': '⡾',
    '1234567': '⡿',
    '8': '⢀',
    '18': '⢁',
    '28': '⢂',
    '128': '⢃',
    '38': '⢄',
    '138': '⢅',
    '238': '⢆',
    '1238': '⢇',
    '48': '⢈',
    '148': '⢉',
    '248': '⢊',
    '1248': '⢋',
    '348': '⢌',
    '1348': '⢍',
    '2348': '⢎',
    '12348': '⢏',
    '58': '⢐',
    '158': '⢑',
    '258': '⢒',
    '1258': '⢓',
    '358': '⢔',
    '1358': '⢕',
    '2358': '⢖',
    '12358': '⢗',
    '458': '⢘',
    '1458': '⢙',
    '2458': '⢚',
    '12458': '⢛',
    '3458': '⢜',
    '13458': '⢝',
    '23458': '⢞',
    '123458': '⢟',
    '68': '⢠',
    '168': '⢡',
    '268': '⢢',
    '1268': '⢣',
    '368': '⢤',
    '1368': '⢥',
    '2368': '⢦',
    '12368': '⢧',
    '468': '⢨',
    '1468': '⢩',
    '2468': '⢪',
    '12468': '⢫',
    '3468': '⢬',
    '13468': '⢭',
    '23468': '⢮',
    '123468': '⢯',
    '568': '⢰',
    '1568': '⢱',
    '2568': '⢲',
    '12568': '⢳',
    '3568': '⢴',
    '13568': '⢵',
    '23568': '⢶',
    '123568': '⢷',
    '4568': '⢸',
    '14568': '⢹',
    '24568': '⢺',
    '124568': '⢻',
    '34568': '⢼',
    '134568': '⢽',
    '234568': '⢾',
    '1234568': '⢿',
    '78': '⣀',
    '178': '⣁',
    '278': '⣂',
    '1278': '⣃',
    '378': '⣄',
    '1378': '⣅',
    '2378': '⣆',
    '12378': '⣇',
    '478': '⣈',
    '1478': '⣉',
    '2478': '⣊',
    '12478': '⣋',
    '3478': '⣌',
    '13478': '⣍',
    '23478': '⣎',
    '123478': '⣏',
    '578': '⣐',
    '1578': '⣑',
    '2578': '⣒',
    '12578': '⣓',
    '3578': '⣔',
    '13578': '⣕',
    '23578': '⣖',
    '123578': '⣗',
    '4578': '⣘',
    '14578': '⣙',
    '24578': '⣚',
    '124578': '⣛',
    '34578': '⣜',
    '134578': '⣝',
    '234578': '⣞',
    '1234578': '⣟',
    '678': '⣠',
    '1678': '⣡',
    '2678': '⣢',
    '12678': '⣣',
    '3678': '⣤',
    '13678': '⣥',
    '23678': '⣦',
    '123678': '⣧',
    '4678': '⣨',
    '14678': '⣩',
    '24678': '⣪',
    '124678': '⣫',
    '34678': '⣬',
    '134678': '⣭',
    '234678': '⣮',
    '1234678': '⣯',
    '5678': '⣰',
    '15678': '⣱',
    '25678': '⣲',
    '125678': '⣳',
    '35678': '⣴',
    '135678': '⣵',
    '235678': '⣶',
    '1235678': '⣷',
    '45678': '⣸',
    '145678': '⣹',
    '245678': '⣺',
    '1245678': '⣻',
    '345678': '⣼',
    '1345678': '⣽',
    '2345678': '⣾',
    '12345678': '⣿'
};

const braille_dic_invert = create_invert_dic();

function create_invert_dic() {
    let result_dic = {};
    let braille_descr_elem_nmbrs = Array.from(Array(8), (_, x) => (x + 1).toString());
    for (const key of Object.keys(braille_descr_dic)) {
        let inverted_char = '';
        for (const nmbr of braille_descr_elem_nmbrs) {
            if (!key.includes(nmbr)) {
                inverted_char += nmbr;
            }
        }
        result_dic[braille_descr_dic[key]] = braille_descr_dic[inverted_char];
    }
    return result_dic;
}