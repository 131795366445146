import React from "react";
import {GalleryTonConnectButton} from "../GalleryTonConnectButton";
import NFTSwiper from "../NFTSwiper";
import {SwiperSlide} from "swiper/react";
import NFTCard, {NFTCardInfo} from "../NFTCard";
import './ProfileEdit.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {Profile} from "../ProfileContext";
import {profileBackend} from "./ProfileBackend";
import {BackendTokenContext} from "../BackendTokenContext";
import {AddNFT} from "./AddNFT";
import {EventEmitter} from "../Common/EventEmitter";
import {connect} from "react-redux";
import {startLoading, stopLoading} from "../store";


interface Props {
    profile: Profile;
    startLoading: () => void;
    stopLoading: () => void;
}

class ProfileEditA extends React.Component<Props, {
    profile: Profile,
    nfts: NFTCardInfo[]
}> {
    static contextType = BackendTokenContext;
    private readonly scrollerPilot: EventEmitter;


    constructor(props: Props) {
        super(props);
        this.state = {profile: this.props.profile, nfts: []};
        this.scrollerPilot = new EventEmitter();
    }

    componentDidMount = async () => {
        // @ts-ignore
        this.setState({nfts: await profileBackend.findNFTsForAuthor(this.context!.walletAddress)});
    };

    private saveInfo = async () => {
        this.props.startLoading();
        await profileBackend.updateName(this.state.profile.name!, (this.context! as any).token);
        setTimeout(this.props.stopLoading, 200);
    };
    private saveInstagram = async () => {
        this.props.startLoading();
        await profileBackend.updateInstagram(this.state.profile.instagram!, (this.context! as any).token);
        setTimeout(this.props.stopLoading, 200);
    };

    private onNFTAdded = (nft: NFTCardInfo) => {
        console.log(nft);
        this.setState({nfts: [nft, ...this.state.nfts]});
        this.scrollerPilot.emit('scrollNext');
    }

    private handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({profile: {...this.state.profile, name: event.target.value}});
    };
    private handleInstagramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({profile: {...this.state.profile, instagram: event.target.value}});
    };


    render() {
        return <div className={"profile-edit"}>
            <div className="profile-edit-wallet"><GalleryTonConnectButton type={'s'}/></div>
            <div className="profile-edit-editables">
                <div className="profile-edit-form-field">
                    <div className="profile-edit-form-field-icon">
                        <FontAwesomeIcon icon={faInstagram} color={'white'}/>
                    </div>
                    <div className="profile-edit-form-field-value">
                        <input type="text" placeholder={'YOUR INSTAGRAM'}
                               value={this.state.profile.instagram}
                               onChange={this.handleInstagramChange}/>
                    </div>
                    <div className="profile-edit-form-field-button inverted">
                        <button onClick={this.saveInstagram}>SAVE</button>
                    </div>
                </div>
                <div className="profile-edit-form-field">
                    <div className="profile-edit-form-field-icon">
                        <FontAwesomeIcon icon={faUser} color={'white'}/>
                    </div>
                    <div className="profile-edit-form-field-value">
                        <input type="text" placeholder={'YOUR NICKNAME'} value={this.state.profile.name}
                               onChange={this.handleNameChange}/>
                    </div>
                    <div className="profile-edit-form-field-button inverted">
                        <button onClick={this.saveInfo}>SAVE</button>
                    </div>
                </div>
                <div className="profile-edit-nfts">
                    <NFTSwiper pilot={this.scrollerPilot}>
                        <SwiperSlide className={'nft-swiper-slide'}>
                            <AddNFT onNFTAdded={this.onNFTAdded}/>
                        </SwiperSlide>
                        {this.state.nfts.map(i => <SwiperSlide className={'nft-swiper-slide'}>
                            <NFTCard info={i}/>
                        </SwiperSlide>)}
                    </NFTSwiper>
                </div>
            </div>
        </div>;
    }
}

const mapDispatchToProps = {
    startLoading,
    stopLoading,
};

export const ProfileEdit = connect(null, mapDispatchToProps)(ProfileEditA);