import React, {createRef} from "react";
import {BuyButton} from "./BuyButton";
import './MainScene.scss';
import {LogoPreview} from "./LogoPreview";
import {Collection} from "./Collection";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
// @ts-ignore
import {ProfileConnectAuthMiddleware} from "./profile/ProfileConnectAuthMiddleware";
import {NFTCardInfo} from "./NFTCard";
import {nftBackend} from "./profile/NFTBackend";
import {connect} from "react-redux";
import {Loader} from "./Loader";

class MainSceneA extends React.Component<{
    loading: boolean,
}, {
    scroll: boolean,
    author: string,
    activeScreen: 'logo-preview' | 'scroller',
    menuState: 'open' | 'close' | 'slide-in' | 'slide-out',
    collection: NFTCardInfo[]
}> {
    private background: React.RefObject<HTMLDivElement>;
    private height: number | undefined;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            scroll: false,
            author: 'Gurt Agency',
            activeScreen: 'logo-preview',
            menuState: 'close',
            collection: [],
        };
        this.background = createRef();
    }

    onScroll = (el: any) => {
        if (this.background.current) {
            this.background.current.style.height = el.visibleSlides[0].children[0].offsetHeight + 16 + 'px';
        }
        this.setState({scroll: true, author: el.visibleSlides[0].children[0].dataset.author ?? 'Gurt Agency'});
        setTimeout(() => {
            this.setState({scroll: false});
        }, 300);
    };

    componentDidMount = async () => {
        setTimeout(() => {
            if (this.background.current && this.height) {
                this.background.current.style.height = `${this.height + 16}px`;
            }
        }, 100);
        setTimeout(() => {
            this.setState({activeScreen: 'scroller'});
        }, 1 );
        this.setState({collection: await nftBackend.findNFTs()});
    };

    private toggleMenu = () => {
        if (this.state.menuState === 'open') {
            this.setState({menuState: 'close'});
        } else {
            this.setState({menuState: 'open'});
        }
    };

    private closeMenu = () => {
        this.setState({menuState: 'close'});
    };

    render() {
        return (
            <Router>
                <div className={"main-scene"}>
                    <div className="main-scene-background">
                        <div>
                            <div
                                className={`main-scene-background-lines main-scene-background-lines-top ${this.state.scroll ? 'main-scene-background-lines-scroll' : ''}`}>
                                {[...Array(100)].map(_ => <div>
                                    <div></div>
                                </div>)}
                            </div>
                            <div
                                className={`main-scene-background-lines main-scene-background-lines-bottom ${this.state.scroll ? 'main-scene-background-lines-scroll' : ''}`}>
                                {[...Array(100)].map(_ => <div>
                                    <div></div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className={"main-scene-topbar"}>
                        <div className={`main-scene-topbar-menu ${this.state.menuState}`}>
                            <ul>
                                <li><Link to="/" onClick={this.toggleMenu}><h3>HOME</h3></Link></li>
                                <li><Link to="/profile" onClick={this.toggleMenu}><h3>FOR ARTISTS</h3></Link></li>
                            </ul>
                        </div>
                        <div className={`main-scene-topbar-menu-toggler`} onClick={this.toggleMenu}>
                            <img src="/img/main-scene/menu.svg" alt=""/>
                        </div>
                        <div className="main-scene-topbar-collection-name">
                            <h3 className={'inverted'}>NOT NFT GALLERY</h3>
                        </div>
                    </div>
                    <div className="main-scene-main">
                        <div className={`main-scene-main-loading main-scene-main-loading-${this.props.loading ? 'active' : 'inactive'}`}>
                            <Loader></Loader>
                        </div>
                        <Routes>
                            <Route path="/profile" element={<ProfileConnectAuthMiddleware/>}>
                            </Route>
                            <Route path="/" element={this.state.activeScreen === 'scroller' ?
                                <Collection onScroll={this.onScroll} collection={this.state.collection}/> :
                                <LogoPreview/>
                            }>
                            </Route>
                        </Routes>
                    </div>
                    <div className={"main-scene-bottom"}>
                        <BuyButton/>
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = (state: { loading: boolean }) => ({
    loading: state.loading,
});
export const MainScene = connect(mapStateToProps)(MainSceneA)